
<template>
  <div class="body">
    <el-carousel :height="bannerHeight + 'px'" arrow="never" style="width: 1200px;margin: 0 auto;">
      <el-carousel-item class="carousel-item" v-for="(item, index) in swiperList" :key="index">
        <img ref="bannerImg" class="carousel-img" @load="onImg" :src="item.picture" />
      </el-carousel-item>
    </el-carousel>
    <div class="select-course">
      <div class="select-course-head">
        <div class="select-course-head-text" v-for="(item, index) in categoryList" :key="index"
          :style="'background-color:' + (index == classification ? '#F6F6F6;' : '#fff')"
          @click="selectClassification(item.id, index)">
          <span>{{ item.name }}</span>
          <div class="lineSelect" :style="'background-color:' + (index == classification ? '#000000;' : '#fff')"></div>
        </div>
      </div>
      <div class="select-course-item">
        <div class="select-course-item-text" v-for="(item, index) in courseList" :key="index"
          @click="selectCourse(item.id)"
          :style="'background-color:' + (item.id == selectCourseId ? '#4394ff' : '#fff;border: 1px solid #C7C7C7;') + ';color:' + (item.id == selectCourseId ? '#fff' : '')">
          {{ item.name }}{{ index }}
        </div>
      </div>
    </div>
    <div class="content">
      <!-- 热门视频 -->
      <div class="content-video margin-top-50">
        <div class="content-video-top">
          <div>
            <div class="content-video-top-text content-video-top-style">热门视频</div>
            <div class="line"></div>
          </div>
          <button class="content-video-top-button" @click="allVideo('video')">
            全部视频
          </button>
        </div>
        <!-- 课程列表 -->
        <div v-if="hotVideos.length > 0" class="margin-top-25">
          <div class="content-video-bottom">
            <div class="content-video-item" v-for="(item, index) in hotVideos" :key="index" @click="viewVideo(item)">
              <img v-if="item.coverUrl != '' && item.coverUrl != null" :src="item.coverUrl"
                class="content-video-item-top" />
              <img v-else src="../../assets/empty.png" class="content-video-item-top" />
              <div class="content-video-item-bottom">
                <div class="content-bottom-title">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="margin-top-25">
          <el-empty description="暂无数据"></el-empty>
        </div>
      </div>
      <!-- 免费直播 -->
      <div class="content-video margin-top-50">
        <div class="content-video-top">
          <div>
            <div class="content-video-top-text content-video-top-style">免费直播</div>
            <div class="line"></div>
          </div>
          <button class="content-video-top-button" @click="allVideo('live')">
            全部直播
          </button>
        </div>
        <!-- 课程列表 -->
        <div v-if="lives.length > 0" class="margin-top-25">
          <div class="content-video-bottom">
            <div class="content-video-item" v-for="(item, index) in lives" :key="index" @click="viewLive(item)">
              <div class="live-label">
                <img v-if="item.cover != '' && item.cover != null" :src="item.cover" class="content-video-item-top" />
                <img v-else src="../../assets/empty.png" class="content-video-item-top" />
                <span v-if="item.start < item.newDate" style="background-color: #ff5e90;">直播中</span>
                <span v-else style="background-color: rgba(0,0,0,0.5)">未开播</span>
              </div>
              <div class="content-video-item-bottom">
                <div class="content-bottom-title">{{ item.name }}</div>
                <div class="gray-14">{{ item.courseName }}</div>
                <div class="gray-14">直播时间：{{ item.startTime ? item.startTime.substring(5, 16) : '' }}</div>

                <div class="gray-14" style="display: flex;"><img :src="item.lecturerAvatar" alt=""
                    class="live-imgTitle">
                  <div>{{ item.lecturerName }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="margin-top-25">
          <el-empty description="暂无数据"></el-empty>
        </div>
      </div>
      <!-- 专业培训 -->
      <div class="content-video margin-top-50">
        <div class="content-video-top">
          <div>
            <div class="content-video-top-text content-video-top-style">专业培训</div>
            <div class="line"></div>
          </div>
        </div>
        <div class="train margin-top-25">
          <img src="../../assets/zhuanyepeixun.png" width="970px" height="360px" />
        </div>
      </div>
      <!-- 教研团队 -->
      <div class="content-video margin-top-50">
        <div class="content-video-top">
          <div>
            <div class="content-video-top-text content-video-top-style">教研团队</div>
            <div class="line"></div>
          </div>
        </div>
        <div class="team" style="margin-top: 20px;margin: none;">
          <img src="../../assets/jiaoyantuandui.png" width="1128px" />
        </div>
      </div>
      <!-- 考试辅导书 -->
      <div class="content-video">
        <div class="content-video-top">
          <div>
            <div class="content-video-top-text-2 content-video-top-style">考试辅导书</div>
            <div class="line"></div>
          </div>
        </div>
        <div class="team margin-top-25">
          <img src="../../assets/kaoshifudaoshu.png" width="978px" />
        </div>
      </div>
      <!-- 班型介绍 -->
      <div class="content-video margin-top-50">
        <div class="content-video-top">
          <div>
            <div class="content-video-top-text content-video-top-style">班型介绍</div>
            <div class="line"></div>
          </div>
        </div>
        <div class="team margin-top-25">
          <img src="../../assets/banxingjiesao.png" width="978px" />
        </div>
      </div>
      <!-- 学员评价 -->
      <div class="content-video margin-top-50">
        <div class="content-video-top">
          <div>
            <div class="content-video-top-text content-video-top-style">学员评价</div>
            <div class="line"></div>
          </div>
        </div>
        <div class="team margin-top-25">
          <img src="../../assets/xueyuanpingjia.png" width="978px" />
        </div>
      </div>
      <!-- 上课图片 -->
      <div class="content-video margin-top-50">
        <div class="content-video-top">
          <div>
            <div class="content-video-top-text content-video-top-style">上课图片</div>
            <div class="line"></div>
          </div>
        </div>
        <div class="team margin-top-25">
          <img src="../../assets/shangketupian.png" width="978px" />
        </div>
      </div>
      <footer class="footer" style="width: 86%;">
        <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2021014802号</a>
      </footer>
    </div>
  </div>
</template>

<script>
import {
getLiveList,
hotKnowledgePoints,
getHotInfo,
getLiveInfo,
getLivePlay,
getCourse,
getCategoryList,
getSwiper,
} from "@/api/course";
export default {
data() {
return {
random: "",
classification: 0,
liveItem: {},
categoryList: [],
courseList: [],
swiperList: [],
hotVideos: [],
lives: [],
page: 1,
selectCourseId: 0,
courseId: 0,
bannerHeight: 0,
};
},
mounted() {
this.getSwiper();
this.getCategoryList();

this.onImg();
window.addEventListener('resize', () => {//监听窗口
this.onImg()
}, false)
},
methods: {
// 获取上次选择的分类
async getHistory() {
var classification = localStorage.getItem("classification");
var classificationId = localStorage.getItem("classificationId");
if (classification != undefined && classificationId != undefined) {
this.classification = classification;
this.selectClassification(classificationId, classificationId);
}
},
onImg() {
this.$nextTick(() => {
this.bannerHeight = this.$refs.bannerImg && this.$refs.bannerImg.length ? this.$refs.bannerImg[0].height : "";
})
},

getSwiper() {
getSwiper({ type: 1, flag: 1 }).then((res) => {
console.log(res);
this.swiperList.push(...res.data);
return
});
},
// 点击全部视频
allVideo(event) {
this.$router.push({
path: "/allVideo",
query: { type: event, id: this.selectCourseId },
});
},
selectCourse(id) {
this.selectCourseId = id;
this.hotKnowledgePoints();
this.getLiveList();
},
// 选择分类
selectClassification(id, index) {
this.classification = index;
localStorage.setItem("classification", this.classification);
localStorage.setItem("classificationId", id);
this.getCourseDetail(id);
},
// 获取分类
getCategoryList() {
getCategoryList().then((res) => {
if (res.code == 200) {
this.categoryList = res.data;
this.getCourseDetail(this.categoryList[0].id);
}
});
},
// 获取详细课程
getCourseDetail(courseCategoryId) {
getCourse({
courseCategoryId,
}).then((res) => {
if (res.code == 200) {
this.courseList = res.data.list;
this.selectCourseId = this.courseList[0].id;
this.hotKnowledgePoints();
this.getLiveList();
} else {
this.courseList = [];
}
});
},
getLiveList() {
getLiveList({ courseId: this.selectCourseId, free: 1, limit: 4 }).then(
(res) => {
if (res.code == 200) {
this.lives = res.data.list;

this.lives.forEach((item, index) => {
var startTime = Number(new Date(item.startTime))//直播开始时间
var newDate = Number(new Date());     //当前时间
this.lives[index]['start'] = startTime
this.lives[index]['newDate'] = newDate
})
}
}
);
},
hotKnowledgePoints() {
hotKnowledgePoints({
courseId: this.selectCourseId,
limit: 4,
page: 1,
}).then((res) => {
if (res.code == 200) {
this.hotVideos = res.data.list;
}
});
},
viewVideo(item) {
getHotInfo({ id: item.id }).then((res) => {
if (res.code == 200) {
this.$router.push({ path: "viewVideo", query: res.data });
}
});
},
viewLive(item) {
//直播中
if (item.start < item.newDate) {
getLiveInfo(item.id).then((res) => {
if (res.code == 200) {
this.liveItem = res.data;
// used为0时才需要输入随机码
if (this.liveItem.used === 0) {
this.inputRandom();
} else {
this.openLive();
}
}
});
} else {
this.$message({
message: '还未开播哦~',
type: 'warning'
});
}

},
openLive() {
// 输入随机码先检测随机码的正确性
getLivePlay({ random: this.random, id: this.liveItem.id }).then((res) => {
if (res.code == 200) {
this.$router.push({
path: "/viewLive",
query: { random: this.random, data: this.liveItem },
});
}
});
},
inputRandom() {
this.$prompt("请输入随机码", {
confirmButtonText: "确定",
cancelButtonText: "取消",
showClose: false,
}).then(({ value }) => {
if (value != null && value != "") {
this.random = value;
this.openLive();
} else {
this.$message.error("随机码不能为空");
}
});
},
},
components: {},
};
</script>

<style lang="scss" scoped>
.train {
  width: 970px;
  height: 360px;
  margin: 0 auto;
}

.team {
  width: 1150px;
  height: auto;
  margin: 0 auto;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-50 {
  margin-top: 50px;
}

.content {
  width: 1200px;
  height: 1602px;
  margin: 0 auto;

  .content-video {
    width: 100%;
    height: auto;

    .content-video-top {
      display: flex;
      justify-content: space-between;

      .content-video-top-text {
        width: 80px;
      }

      .content-video-top-text-2 {
        width: 100px;
      }

      .content-video-top-style {
        font-weight: 400;
        font-size: 20px;
      }

      .content-video-top-button {
        width: 70px;
        height: 26px;
        border-radius: 23px;
        border: 1px solid #4394ff;
        color: #4394ff;
        font-size: 10px;
        background-color: white;
        margin-right: 20px;
        cursor: pointer;
      }
    }

    .content-video-bottom {
      display: flex;

      .content-video-item {
        width: 260px;
        margin-right: 58px;
        border: 2px solid #E8E8E8;
        border-radius: 5px;
        cursor: pointer;

        .content-video-item-top {
          width: 100%;
          height: 180px;
          border-radius: 5px;
        }

        .content-video-item-bottom {
          width: 247px;

          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          text-align: left;
          padding-left: 10px;

          .content-bottom-title {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
          }

          .content-bottom-subtitle {
            padding-top: 10px;
            font-size: 14px;
            color: #646464;
          }

          .content-bottom-content {
            display: -webkit-inline-box;
            line-height: 15px;
            font-size: 12px;
          }

          .gray-14 {
            font-size: 14px;
            font-weight: 400;
            color: #646464;
          }

          div {
            margin: 10px 0;
          }
        }
      }
    }
  }
}

.content-title {
  margin-top: 50px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  text-align: left;
  width: 80px;
  border-radius: 5px;
  border-bottom: 3px solid #4394ff;
}

.line {
  height: 5px;
  width: 100%;
  margin-top: 4px;
  background-color: #4394ff;
  border-radius: 10px;
}

.select-course {
  width: 1150px;
  height: auto;
  background: #ffffff;
  margin: 50px auto 0 auto;
  padding: 25px;

  .select-course-head {
    width: 100%;
    // height: 50px;

    .select-course-head-text {
      display: inline-block;

      height: 25px;
      font-size: 18px;
      font-weight: 400;
      margin-right: 30px;
      line-height: 25px;
      color: #000000;
      padding-bottom: 3px;
      cursor: pointer;
      padding: 10px 20px;
      border-radius: 5px 5px 0px 0px;
    }
  }

  .lineSelect {
    margin-left: 20px;
    width: 20px;
    height: 2px;
  }

  .select-course-item {
    // margin-top: 20px;
    padding: 30px;
    background-color: #F6F6F6;

    .select-course-item-text {
      display: inline-block;
      margin-right: 30px;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      cursor: pointer;
      border-radius: 5px;
      padding: 10px 20px;
      margin-bottom: 15px;
    }
  }
}

.live-label {
  position: relative;

  span {
    width: 51px;
    height: 18px;
    position: absolute;
    top: 4px;
    right: 4px;
    display: inline-block;

    font-size: 12px;
    color: white;
    border-radius: 2px;
    box-sizing: border-box;
    line-height: 18px;
    text-align: center;

  }

  .carousel-item {
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
  }

  .carousel-img {
    max-width: 100%;
    max-height: 600px;
    height: 600px;
    background-color: #000000;
    background: url('https://www.zhijiao168.com/cloudClassroom/source/img/20211014/42229cf30d054157b6db8803e6d4e203.jpg');
  }
}

.live-imgTitle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 10px;
}
</style>